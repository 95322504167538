import Vue from 'vue';
import Login from './login.vue';
import router from "./router";
import store from "./store";

import '@/assets/style/login/dark-variables.scss';
import YDUI from 'vue-ydui';
Vue.use(YDUI);
import Toast from 'vue2-toast';
Vue.use(Toast);
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faSpinner, faSortDown, faSortUp);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

window.Vue_login = new Vue({
  router,
  store,
  methods: {
    resumePage () {
      this.callResumeMethod(this.$children);
    },
    callResumeMethod (child) {
      if (child && child.length > 0) {
        for (var ci = 0, cl = child.length; ci < cl; ci++) {
          if (typeof child[ci].onPageResume === 'function') {
            child[ci].onPageResume();
          }
          this.callResumeMethod(child[ci].$children);
        }
      }
    },
    stopPage () {
      this.callStopMethod(this.$children);
    },
    callStopMethod (child) {
      if (child && child.length > 0) {
        for (var ci = 0, cl = child.length; ci < cl; ci++) {
          if (typeof child[ci].onPageStop === 'function') {
            child[ci].onPageStop();
          }
          this.callStopMethod(child[ci].$children);
        }
      }
    }
  },
  render: h => h(Login)
}).$mount('#login');

