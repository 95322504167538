import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const state = {
  login: {},
  classes: {},
  booking: {},
  materialName: '',
  homeWorkData: 0,
  AudioIndex: 9999,
  timestamp: parseInt(new Date().getTime() / 1000),
  materials: {
    active_component: 1,
    show_modal: false,
    grade_info: {},
    mat_info: {},
    selected_num: null
  },
  screenTheme: 'LIGHT'
};
const getters = {
  getScreenTheme: (state) => {
    return state.screenTheme;
  },
  loginUid: (state) => {
    if (state.login && state.login.uid) {
      return state.login.uid;
    }
    return 0;
  },
  homeWorkData: (state) => {
    if (state.homeWorkData) {
      return state.homeWorkData;
    }
    return 0;
  },
  materialName: (state) => {
    return state.materialName;
  },
  classes: (state) => state.classes || 0,
  share: (state) => {
    if (state.classes && state.classes.share_permission &&
      state.classes.share_permission.status) {
      return state.classes.share_permission.url;
    }
    return 0;
  },
  notification: (state) => {
    if (state.classes && state.classes.notification)
      return state.classes.notification;
    return {};
  },
  Calendar: (state) => {
    let tempDate = [];
    if (state.classes.list && state.classes.list.length > 0) {
      let classes = state.classes.list;
      if (classes.length > 0) {
        for (var i in classes) {
          let list = [];
          let currentDate = new Date(classes[i].date).getDate();
          let currentMonth = new Date(classes[i].date).getMonth() + 1;
          if (!tempDate[currentMonth]) {
            tempDate[currentMonth] = {
              date: '',
              list: {}
            };
          }
          for (let s in classes[i].list) {
            // let label = ( classes[i].list[s].lessontype === -1 ) ? '线下课程' : '线上课程';
            // let text = classes[i].list[s].time + label;
            let text = classes[i].list[s].time;
            list.push({
              time: classes[i].list[s].time,
              title: classes[i].list[s].title
            });
          }
          tempDate[currentMonth].date = classes[i].date;
          tempDate[currentMonth].list[currentDate] = list;
          tempDate[currentMonth].list[currentDate].color = classes[i].color;
        }
      }
    }
    console.log(tempDate);
    return tempDate;
  },
  booking: (state) => state.booking || {},
  materialAudioIndex: (state) => state.AudioIndex || 9999,
  getTimestamp: (state) => state.timestamp || parseInt(new Date().getTime() / 1000)
};
const actions = {

};
const mutations = {
  setScreenTheme (state, screenTheme) {
    state.screenTheme = screenTheme;
  },
  setTimestamp (state, timeStamp) {
    state.timestamp = timeStamp;
  },
  saveLogin (state, resultData) {
    state.login = resultData;
  },
  setClasses (state, resultData) {
    state.classes = resultData;
  },
  setBooking (state, resultData) {
    state.booking = resultData;
  },
  setHomeworkData (state, resultData) {
    if (resultData) {
      state.homeWorkData = resultData;
    }
  },
  setActiveMaterial (state, materialName) {
    state.materialName = materialName;
  },
  setMaterialAudioIndex (state, AudioIndex) {
    state.AudioIndex = AudioIndex;
  },
  change_component (state, component) {
    state.materials.active_component = component;
  },
  close_material_modal (state) {
    state.materials.active_component = 1;
    state.materials.show_modal = false;
  },
  show_mat_mod (state) {
    state.materials.show_modal = true;
  },
  change_state_val (state, post) {
    state.materials[post.name] = post.data;
  }
};
const vuexStore = new Vuex.Store(
  {
    state,
    getters,
    actions,
    mutations,
    strict: process.env.NODE_ENV !== 'production'
  }
);
export default vuexStore;