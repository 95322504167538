<template>
  <div id="login" :class="[screenColor == 'DARK' ? 'dark' : '']">
    <router-view />
  </div>
</template>

<script>
const globalAppDatash = {
  wnd_h: window.innerHeight,
};
export default {
  name: 'Login',
  data() {
    return globalAppDatash;
  },
  computed: {
    screenColor() {
      return this.$store.getters.getScreenTheme;
    },
  },
  //判断主题（深色/浅色）
  watch: {
    '$route.query.appstyle'(val) {
      if (val == 'dark') {
        this.$store.commit('setScreenTheme', 'DARK');
      } else if (val == 'light') {
        this.$store.commit('setScreenTheme', 'LIGHT');
      }
    },
  },
  mounted() {
    if (window.innerHeight != 0) {
      this.wnd_h = window.innerHeight;
    }
  },
};
</script>

<style>
@font-face {
  font-family: 'newjoy';
  src: url('../../assets/fonts/newjoy.ttf');
}
body,
a,
table,
div,
span,
td,
th,
input,
select,
p,
li,
textarea {
  font-family: newjoy !important;
}
.joy_logo {
  position: absolute;
  right: 27px;
  bottom: 10px;
  width: 35%;
}
#login {
  height: 100%;
  position: relative;
  max-width: 100vw;
}
</style>
