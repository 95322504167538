import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    {
      // 设置默认路由
      path: '/',
      redirect: '/password'
    },
    {
      // 验证码登录
      path: '/tel',
      name: 'tel',
      component: () => import('./views/tel'),
    },
    {
      // 密码登录
      path: '/password',
      name: 'password',
      component: () => import('./views/passWord')
    },
    {
      // 新用户注册
      path: '/new',
      name: 'new',
      component: () => import('./views/new')
    },
    {
      // 设置登录密码
      path: '/setPassword',
      name: 'setPassword',
      component: () => import('./views/setPassword')
    },
    {
      // 忘记密码
      path: '/forgetPassword',
      name: 'forgetPassword',
      component: () => import('./views/forgetPassword')
    },
	{
	  // 学习机密码登录
	  path: '/LearningMachinePassword',
	  name: 'LearningMachinePassword',
	  component: () => import('./views/LearningMachinePassword')
	},
	{
	  // 学习机手机验证码登录
	  path: '/LearningMachinetel',
	  name: 'LearningMachinetel',
	  component: () => import('./views/LearningMachinetel')
	},
	
	{
	  // 学习机手机验证码登录
	  path: '/LearningMachineforgetPassword',
	  name: 'LearningMachineforgetPassword',
	  component: () => import('./views/LearningMachineforgetPassword')
	},
    {
      path: '/home',
      name: 'Home',
      beforeEnter (to, from, next) {
        window.location = window.location.origin + '/home/index.html#/';
      }
    },
  ]
});
